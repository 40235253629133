import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import Franchise from './components/Franchise';
import Footer from './components/Footer';
import JoinUs from './components/JoinUs';
import Career from './components/Career';
import Menu from './components/Menu';
import Home from './components/Home';
import CustomNavbar from './components/CustomNavbar';
import BetroLocation from './components/BetroLocation';

function App() {
  return (
    <div>
      <Router>
        <CustomNavbar></CustomNavbar>
        <Routes>
          <Route path="/kariyer" element={<Career />} />
          <Route path="/iletisim" element={<JoinUs />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/betro-nerede" element={<BetroLocation />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
