import React, { useState } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BetroLocation.css';

function BetroLocation() {

  return (
    <Container fluid className='betro-location-container'>
      <img className='secpic' src="https://betro.s3.eu-central-1.amazonaws.com/secpic.png" alt="SecPic" />
      <img className='onepic' src="https://betro.s3.eu-central-1.amazonaws.com/onepic.png" alt="OnePic" />
      <Row>
        <Col style={{ marginTop: '20px' }}>
          <h1 style={{ marginBottom: '20px' }}>BEŞİKTAŞ</h1>
          <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12036.594444941657!2d29.0019298!3d41.0438791!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab79c4a374005%3A0x30a4b424b65abdce!2sBetro%20Burger!5e0!3m2!1str!2str!4v1724159436987!5m2!1str!2str" loading="lazy"></iframe>
        </Col>
      </Row>
      <Row>
        <Col style={{ margin: '40px 0 40px 0' }}>
          <h1 style={{ marginBottom: '20px' }}>KADIKÖY</h1>
          <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12046.736175851003!2d29.0274535!3d40.9883989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9e9acb2f707%3A0x806d0be4a6d6390d!2sBetro%20Burger%20Kad%C4%B1k%C3%B6y!5e0!3m2!1str!2str!4v1724159467574!5m2!1str!2str" loading="lazy"></iframe>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '20px' }}>
          <h1 style={{ marginBottom: '20px' }}>TAKSİM</h1>
          <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12039.20710193179!2d28.975149!3d41.0295925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9393212be03%3A0x6be093267dacfc41!2sBetro%20Burger%20Taksim!5e0!3m2!1str!2str!4v1724159399540!5m2!1str!2str" loading="lazy"></iframe>
        </Col>
      </Row>
    </Container>
  );
}

export default BetroLocation;
