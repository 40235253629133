import './MenuSection.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import StarRatings from 'react-star-ratings';

function MenuSection() {
  const [selectedMenu, setSelectedMenu] = useState("Burger");
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const [productname, setProductname] = useState("");
  const [productdescription, setProductdescription] = useState("");
  const [productimg, setProductimg] = useState("");
  const [productprice, setProductprice] = useState("");
  const [productvote, setProductvote] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (img, name, description, price, vote) => {
    setShow(true);
    setProductname(name);
    setProductdescription(description);
    setProductimg(img);
    setProductprice(price);
    setProductvote(vote);
  };

  const menus = {
    Burger: [
      { name: 'QUBA BETRO', description: '2 Adet (170 gr) Burger Köftesi – 3 Dilim Cheddar –Dana Füme - Napolitan Sos - Karamelize Soğan - Soslu Mantar.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/kubabetro.jpeg" },
      { name: 'KAYSERILI BETRO', description: '2 Adet (170 gr) Burger Köftesi – 3 Dilim Cheddar – Pastırma - Karamelize Soğan - Soslu Mantar.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/kayserilibetro.jpeg" },
      { name: 'ADANALI BETRO', description: '2 Adet (170 gr) Burger Köftesi – 3 Dilim Cheddar – Acı Sos- Jalapeno -  Karamelize Soğan – Soslu Mantar.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/adanalibetro.jpeg" },
      { name: 'KLASIK BETRO', description: '2 Adet (170 gr) Burger Köftesi – 3 Dilim Cheddar - Karamelize Soğan - Soslu Mantar.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/klasikbetro.jpeg" },
      { name: 'BADIL BETRO', description: '2 Adet (170 gr) Burger Köftesi – 2 Dilim Cheddar - Tulum Peyniri - Köz Patlıcan - Köz Kırmızı Biber.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/badilbetro.jpeg" },
      { name: 'SMALL BETRO', description: '1 Adet (85 gr) Burger Köftesi - 2 Dilim Cheddar - Dana Füme - Karamelize Soğan - Soslu Mantar.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/smallbetro.jpeg" },
      { name: 'DUBAI BETRO', description: '3 Adet (255 gr) Burger Köftesi – 3 Dilim Cheddar - Barbekü Sos - Karamelize Soğan – Mantar Sos. Cajun Baharatlı Patates Kızartması ve Mini Calzone ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/dubaibetro.jpeg" },
      { name: 'ÖĞRENCİ MENÜ', description: '1 Adet (85 gr) Burger Köftesi - 2 Dilim Cheddar - Dana Füme - Karamelize Soğan - Soslu Mantar. Patates Kızartması ve İçecek', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/ogrencimenu.jpeg" },
      { name: 'ANATOLIAN BETRO', description: '2 Adet (170 gr) Burger Köftesi – 2 Dilim Cheddar –Tulum Peyniri - Dana Sucuk .  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/anatolianbetro.jpeg" },
      { name: 'SMOKED BETRO', description: '2 Adet (170 gr) Burger Köftesi – 3 Dilim Cheddar - Dana Füme - Kıtır Soğan - Tütsülenmiş Barbekü Sos.  Patates Kızartması ile servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/smokedbetro.jpeg" },
    ],
    Calzone: [
      { name: 'MINI CALZONE', description: 'Çikolata Sosu - Tarçın ve Pudra şekeri', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/minicalzone.jpeg" },
      { name: 'ORTA CALZONE', description: 'Çikolata sosu - Tarçın ve Pudra şekeri', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/ortacalzone.jpeg" },
    ],
    KizarmisTavuk: [
      { name: 'CHICKEN WINGS', description: '6 Adet Çıtır Kanat - Patates Kızartması - Ranch Sos, Dynamite Sos ve Sweet Chili Sos', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/chickenwings.jpeg" },
      { name: 'SWEET CHILI CHICKEN WINGS', description: '6 Adet Soslu Çıtır Kanat - Patates Kızartması ve Ranch Sos', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/sweetchilichickenwings.jpeg" },
      { name: 'CHICKEN TENDERS', description: '5 Adet Çıtır Tavuk Parçası - Patates Kızartması - Ranch Sos, Dynamite Sos ve Sweet Chili Sos', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/chickentenders.jpeg" },
      { name: 'DYNAMITE CHICKEN BONELESS', description: "10'lu Soslu Tavuk Parçası - Patates Kızartması ve Ranch Sos", price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/dynamitechickenboneless.jpeg" },
      { name: 'SWEET CHILI CHICKEN BONELESS', description: "10'lu Soslu Tavuk Parçası - Patates Kızartması ve Ranch Sos", price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/sweetchilichickenboneless.jpeg" },
    ],
    Atistirmaliklar: [
      { name: 'BAHARATSIZ PATATES KIZARTMASI', description: 'Burger siparişlerinizin yanında ücretsiz olarak servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/baharatsizpatateskizartmasi.jpeg" },
      { name: 'BAHARATLI PATATES KIZARTMASI', description: 'Burger siparişlerinizin yanında ücretsiz olarak servis edilir.', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/baharatlipatateskizartmasi.jpeg" },
      { name: 'SOGAN HALKASI 5 LI', description: '', price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/soganhalkasi.jpeg" },
      { name: 'SOGAN HALKASI 10 LU', description: "", price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/soganhalkasion.jpeg" },
      { name: 'SOSİS TABAĞI', description: "2 Adet Frankfurter Sosis (200 gr.) - Patates Kızartması - Dynamite Sos - Sweet Chili Sos", price: "", vote: "5", img: "https://betro.s3.eu-central-1.amazonaws.com/sosistabagi.jpeg" },
    ],
  };

  const handleMenuClick = (menu) => {
    if (menus[menu]) {
      setSelectedMenu(menu);
      setCurrentPage(0);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (menus[selectedMenu] && currentPage < Math.ceil(menus[selectedMenu].length / 4) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const maxPages = menus[selectedMenu] ? Math.ceil(menus[selectedMenu].length / 4) : 0;
  const currentItems = menus[selectedMenu] ? menus[selectedMenu].slice(currentPage * 4, (currentPage + 1) * 4) : [];

  return (
    <div className='MenuBottomSideSelectorforMenuContent'>
      <div className='innermenutitle'>
        <div className={(selectedMenu=='Burger' ? 'inner-menu-active' : '')} onClick={() => handleMenuClick('Burger')}>Burgerler</div>
        <div className={(selectedMenu=='Calzone' ? 'inner-menu-active' : '')} onClick={() => handleMenuClick('Calzone')}>Calzone</div>
        <div className={(selectedMenu=='KizarmisTavuk' ? 'inner-menu-active' : '')} onClick={() => handleMenuClick('KizarmisTavuk')}>Kızarmış Tavuk</div>
        <div className={(selectedMenu=='Atistirmaliklar' ? 'inner-menu-active' : '')} onClick={() => handleMenuClick('Atistirmaliklar')}>Atıştırmalıklar</div>
      </div>
      <div className='MenuBottomSlidePanel'>
        <Container>
          <Row>
            {currentItems.map((item, index) => (
              <Col key={index} xs={12} md={6} lg={6} className="menuinneritem">
                <Container>
                  <Row style={{ cursor: 'pointer' }} onClick={() => handleShow(item.img, item.name, item.description, item.price, item.vote)}>
                    <Col xs={12} md={12} lg={6}><img style={{ width: '200px' }} src={item.img} /></Col>
                    <Col xs={12} md={12} lg={6} style={{ marginTop: '20px' }}>
                      <p className='custom-menu-title'>{item.name}</p>
                      <p className='custom-menu-description'>{item.description}</p>
                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: 'flex' }}>
                        </div>
                        <button style={{ border: "none", borderRadius: "20px", color: "white", background: "black", width: "80px" }}>{item.price}</button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
        <div className="slider-arrows">
          {currentPage > 0 && <FaChevronLeft className="arrow arrow-left" onClick={handlePrevPage} />}
          {currentPage < maxPages - 1 && <FaChevronRight className="arrow arrow-right" onClick={handleNextPage} />}
        </div>
      </div>
      <div className='pageshower'>
        {Array.from({ length: maxPages }, (_, index) => (
          <div
            key={index}
            className={`page-indicator ${index === currentPage ? 'active' : ''}`}
            onClick={() => setCurrentPage(index)}
          >
          </div>
        ))}
      </div>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header className='custom-modal-header'>
          <button type="button" class="btn-close btn-close-white" onClick={handleClose} aria-label="Close"></button>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#36A426", color: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px" }}>
          <img src={productimg} alt={productname} style={{ width: "50%" }} />
          <div style={{ marginLeft: "20px" }}>
            <p className='model-product-name'>{productname}</p>
            <p style={{ fontSize: '13px' }}>{productdescription}</p>
            <Row className='model-row'>
              <Col style={{ display: 'flex' }} lg={8} md={8} xs={12}>
              </Col>
              {/* 
              <Col lg={4} md={4} xs={12}>
                <button style={{ border: "none", borderRadius: "20px", color: "white", background: "black", width: "80px", textAlign: 'center' }}>{productprice}</button>
              </Col>
              */}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MenuSection;
