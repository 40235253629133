import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import './Slidermy.css'; // Import the CSS file

function Slidermy() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel-wrapper">
      <img src="https://betro.s3.eu-central-1.amazonaws.com/bgnoise.png" className='bgnoiseformenu' />
      <img src="https://betro.s3.eu-central-1.amazonaws.com/betro.png" alt="Betro" className="betro-img" />
      <img src="https://betro.s3.eu-central-1.amazonaws.com/galaxy.png" alt="Galaxy" className="galaxy-img" />
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item interval={1000}>
          <Row className='menu-row'>
            <Col xs={12} md={6} xl={6} className='menu-title'>
              <div className='Titleofslide'>ADANALI<br /> BETRO</div>
              <div className='contextofslide'>2 Adet (170 gr) Burger Köftesi – 3 Dilim Cheddar – Acı Sos- Jalapeno -  Karamelize Soğan – Soslu Mantar.  Patates Kızartması ile servis edilir.</div>
            </Col>
            <Col xs={12} md={6} xl={6}>
              <img
                className="d-block slide-image"
                src="https://betro.s3.eu-central-1.amazonaws.com/adanali.png"
                alt="First slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <Row className='menu-row'>
            <Col xs={12} md={6} xl={6} className='menu-title'>
              <div className='Titleofslide'>KLASİK<br /> BETRO</div>
              <div className='contextofslide'>Lezzetin klasik tadıyla tanışın: Adanalı Betro burger! Dana köftesi, cheddar peyniri, karamelize soğan, özel soslu mantar ve özel acı sosuyla unutulmaz bir lezzet deneyimi. Tutkunuzu keşfedin!</div>
            </Col>
            <Col xs={12} md={6} xl={6}>
              <img
                className="d-block slide-image"
                src="https://betro.s3.eu-central-1.amazonaws.com/adanali.png"
                alt="First slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <Row className='menu-row'>
            <Col xs={12} md={6} xl={6} className='menu-title'>
              <div className='Titleofslide'>KAYSERİLİ<br /> BETRO</div>
              <div className='contextofslide'>Lezzetin klasik tadıyla tanışın: Adanalı Betro burger! Dana köftesi, cheddar peyniri, karamelize soğan, özel soslu mantar ve özel acı sosuyla unutulmaz bir lezzet deneyimi. Tutkunuzu keşfedin!</div>
            </Col>
            <Col xs={12} md={6} xl={6}>
              <img
                className="d-block slide-image"
                src="https://betro.s3.eu-central-1.amazonaws.com/adanali.png"
                alt="First slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Slidermy;
