import { Container, Nav, Navbar, Image, Button } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import './CustomNavbar.css';

export default function CustomNavbar() {
    const [activeLink, setActiveLink] = useState('');
    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);
    
    return (
        <Navbar className="custom-navbar" bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="/">
                <Image
                    width="150px"
                    src="https://betro.s3.eu-central-1.amazonaws.com/betro_logo.png" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="navbar-collapse-container">
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/" to="/" active={activeLink === '/'} style={{ textAlign: 'center', marginTop: '15px' }}>ANASAYFA</Nav.Link>
                        <Nav.Link href="/menu" to="/Menu" active={activeLink === '/menu'} style={{ textAlign: 'center', marginTop: '15px' }}>MENU</Nav.Link>
                        <Nav.Link href="/kariyer" to="/Career" active={activeLink === '/kariyer'} style={{ textAlign: 'center', marginTop: '15px' }}>KARİYER</Nav.Link>
                        <Nav.Link href="/franchise" to="/Franchise" active={activeLink === '/franchise'} style={{ textAlign: 'center', marginTop: '15px' }}>FRANCHISE</Nav.Link>
                        <Nav.Link href="/iletisim" to="/JoinUs" active={activeLink === '/iletisim'} style={{ textAlign: 'center', marginTop: '15px' }}>İLETİŞİM</Nav.Link>
                        <Nav.Link href="/betro-nerede" to="/BetroLocation" active={activeLink === '/betro-nerede'} style={{ textAlign: 'center', marginTop: '7px' }}>BETRO <br></br>NEREDE?</Nav.Link>
                        <div className="nav-phone-image">
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </div>

        </Navbar>
    );
}