import './Franchise.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaTwitter, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Franchise() {
  return (
    <div className='Franchise-main-container'>
      <div className='bg-pic'>
        <p className='headerforfranchisepage'>FRANCHISE</p>
        <p className='textforfranchisepage'>Lezzetin ve kalitenin bir araya geldiği kapalı burger konseptiyle fast-food endüstrisinde büyümeye devam ediyoruz. Franchise alarak Betro Burger ailesine katılıp kendi işinizi kurma fırsatını yakalayabilirsiniz. Franchise sahibi olmak, size sadece bir işletme değil, aynı zamanda güçlü bir markanın bir parçası olma fırsatı sunuyor. Betro Burger Franchise ailesine katılarak kendi işinizin kurup başarılı bir geleceğe adım atma fırsatı yakalayın!</p>
      </div>
      <div className='betro-pic'></div>
      <div className='formcontent'>
        <img className='bgnoise' src="https://betro.s3.eu-central-1.amazonaws.com/bgnoise.png" />
        <Container style={{zIndex: '1000'}}>
          <img className='onepic' src="https://betro.s3.eu-central-1.amazonaws.com/onepic.png" alt="OnePic" />
          <img className='secpic' src="https://betro.s3.eu-central-1.amazonaws.com/secpic.png" alt="SecPic" />
          <Row className='justify-content-between'>
            <Col xs="12" md="6" lg={7}>
              <ul className='informationcontent'>
                <li className="titleforfranchise">1. MARKA GÜCÜ</li>
                <li className='textforfranchise'>
                  Franchise sahibi olmak, kendi işinin patronu olmanın yanı sıra güçlü bir markanın bir parçası olmayı da içerir. Betro Burger olarak, her adımda size rehberlik etmek ve destek olmak için buradayız.
                </li>
                <li className="titleforfranchise">2. TAM DESTEK VE REHBERLİK</li>
                <li className='textforfranchise'>
                  Franchise sahiplerimize işletme açılışından operasyonel süreçlere kadar her konuda yardımcı oluyoruz. İşletmenin düzeni, aksiyon planları ve diğer tüm birimlerimizle tam destek sağlıyoruz!
                </li>
                <li className="titleforfranchise">3. PAZARLAMA VE SATIŞ</li>
                <li className='textforfranchise'>
                  Franchise sahiplerimize işletme açılışından operasyonel süreçlere kadar her konuda yardımcı oluyoruz. İşletmenin düzeni, aksiyon planları ve diğer tüm birimlerimizle tam destek sağlıyoruz!
                </li>
                <li className="titleforfranchise">4. SÜREKLİ EĞİTİM VE GELİŞİM</li>
                <li className='textforfranchise'>
                  Franchise sahiplerimize işletme açılışından operasyonel süreçlere kadar her konuda yardımcı oluyoruz. İşletmenin düzeni, aksiyon planları ve diğer tüm birimlerimizle tam destek sağlıyoruz!
                </li>
                <li style={{ marginTop: '60px' }} className="titleforfranchise">FRANCHISE FORM</li>
                <li>
                  <Form>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label style={{ color: "white" }}>İsim - Soyisim:</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicBudget">
                          <Form.Label style={{ color: "white" }}>Bütçe:</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label style={{ color: "white" }}>E-mail:</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicCapital">
                          <Form.Label style={{ color: "white" }}>Sermaye Kaynağı:</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicCompanyName">
                          <Form.Label style={{ color: "white" }}>Şirket İsmi:</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicCity">
                          <Form.Label style={{ color: "white" }}>Şehir:</Form.Label>
                          <Form.Control type="text" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicAddress">
                      <Form.Label style={{ color: "white" }}>Adres:</Form.Label>
                      <Form.Control type="email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicExtraInfo">
                      <Form.Label style={{ color: "white" }}>Ek Bilgiler:</Form.Label>
                      <Form.Control as="textarea" rows={4} />
                    </Form.Group>
                    <div style={{textAlign: 'right', marginTop:'20px'}}>
                      <Button type="submit" className="franchise-submit-button">
                        Gönder
                      </Button>
                    </div>
                  </Form>
                </li>
              </ul>
            </Col>
            <Col xs="12" md="6" lg={5} className='d-flex flex-column align-items-center'>
              <img className="astronotpic" src="https://betro.s3.eu-central-1.amazonaws.com/astronot.png" alt="Astronot" />
              <img className="galaxypic" src="https://betro.s3.eu-central-1.amazonaws.com/galaxy.png" alt="Galaxy" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Franchise;
