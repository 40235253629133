import './Footer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom'
import { FaTwitter, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

function Footer() {
  const navigate = useNavigate();

  const handleOnSubmit = (nav) => {
    console.log(nav)
    navigate(nav);
  };

  return (
    <div className='footer-main-container'>
      <img src="https://betro.s3.eu-central-1.amazonaws.com/serit.png" className='footer-line-img'/>
      <Container style={{ color: 'white' }}>
        <Row className='footer-menu' style={{ justifyContent: 'center', textAlign: 'center', padding: '25px 0' }}>
          <Col style={{cursor: 'pointer'}} onClick={() => handleOnSubmit('/')} xs={12} md={2} lg={2}>ANASAYFA</Col>
          <Col style={{cursor: 'pointer'}} onClick={() => handleOnSubmit('/menu')} xs={12} md={2} lg={2}>MENU</Col>
          <Col style={{cursor: 'pointer'}} onClick={() => handleOnSubmit('/kariyer')} xs={12} md={2} lg={2}>KARİYER</Col>
          <Col style={{cursor: 'pointer'}} onClick={() => handleOnSubmit('/franchise')} xs={12} md={2} lg={2}>FRANCHISE</Col>
          <Col style={{cursor: 'pointer'}} onClick={() => handleOnSubmit('/iletisim')} xs={12} md={2} lg={2}>İLETİŞİM</Col>
          <Col style={{cursor: 'pointer'}} onClick={() => handleOnSubmit('/')} xs={12} md={2} lg={2}>BLOG</Col>
        </Row>
        <Row><div className="footer-line"></div></Row>
        <Container className='footer-bottom-container' style={{ paddingTop: '0', paddingBottom: '0' }}>
          <Row style={{ marginBottom: '0', paddingBottom: '0', textAlign: 'center' }}>
            <Col style={{ fontSize: '21px', paddingTop: '10px', paddingBottom: '5px' }}>
              <p className='footer-betro-text'>BETRO BURGER</p>
            </Col>
          </Row>
          <Row style={{ marginBottom: '0', paddingBottom: '10px', textAlign: 'center' }}>
            <Col style={{ fontSize: '14px', padding: '0' }}>
              Designed by Dynamite Agency<br />
              2024
            </Col>
          </Row>
          <Row style={{ marginBottom: '0', paddingBottom: '0', justifyContent: 'center' }}>
            <Col xs="auto" style={{paddingTop:"10px", paddingBottom:"30px" }}>
              <FaFacebook size={25} />
            </Col>
            <Col xs="auto" style={{paddingTop:"10px", paddingBottom:"30px"  }}>
              <FaInstagram size={25} />
            </Col>
            <Col xs="auto" style={{paddingTop:"10px", paddingBottom:"30px"  }}>
              <FaTiktok size={25} />
            </Col>
          </Row>
        </Container>
      </Container>
      <div className="deneme"></div>
    </div>
  );
}

export default Footer;
