import React from 'react';
import './Slidermy.css';
import './MenuSection.css';
import './Menu.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slidermy from './Slidermy';
import MenuSection from './MenuSection';
function Menu() {

  return (
    <div>
      <Slidermy/>
      <MenuSection/>
    </div>
  );
}

export default Menu;
