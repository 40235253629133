import './Home.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate();

  const handleOnSubmit = () => {
    navigate('/menu');
  };

  return (
    <div className='ortam'>
      <Container fluid className="firstcomponentinhomepage" style={{ width: "100%", backgroundColor: "black", height: "auto", position: "relative" }}>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <img className='welcome-text' src="https://betro.s3.eu-central-1.amazonaws.com/welcome-to-betroland.png" />
          <img className='homepage-hamburger' src="https://betro.s3.eu-central-1.amazonaws.com/Katman3.png" />
          <img className='start-one' src="https://betro.s3.eu-central-1.amazonaws.com/start.png" />
          <img className='start-ones' src="https://betro.s3.eu-central-1.amazonaws.com/start.png" />
        </Row>
        <Row>
          <Col xs={6}>
            <p className='homepage-text-1'>BURGER DEĞİL</p>
            <p className='homepage-text-2'>BETRO</p>
            <p className='homepage-text-3'>Lezzetin adresi Betro Burger! Taptaze malzemeler ve eşsiz tatlarla dolu burger deneyimini keşfedin. Her lokmada mutluluğa bir adım daha yakınsınız.</p>
            <div className='home-btn-1' onClick={handleOnSubmit}>
              <div className='home-btn-2'>
                MENÜ
              </div>
            </div>
          </Col>
          <Col xs={6} style={{ display: 'flex', paddingBottom: "0px", justifyContent: 'flex-end', alignItems: 'center', paddingRight: 0 }}>
            <img src="https://betro.s3.eu-central-1.amazonaws.com/home_hand_img.png" style={{ width: "100%" }} />
          </Col>
        </Row>
      </Container>

      <div className='containerto'>
        <img src="https://betro.s3.eu-central-1.amazonaws.com/betro.png" className='line' />
        <div className='imgsidecontainerto'>
          <img src="https://betro.s3.eu-central-1.amazonaws.com/serit.png" className='serit' />
          <img className="heykel" src="https://betro.s3.eu-central-1.amazonaws.com/homepage_astronot.png" />
        </div>
        <div className='textsidecontainerto'>
          <p className='homepage-text-4'>BİZ</p>
          <p className='homepage-text-4'>KİMİZ?</p>
          <p className='homepage-text-6'>Betro Burger, kapalı burger konseptiyle damakları şenlendiren bir fast-food zinciridir. Kalite, lezzet ve yenilik üzerine kurulu olan markamız, müşterilerimize her ısırıkta unutulmaz bir deneyim sunmayı amaçlamaktadır. Müşteri memnuniyetini ve kaliteyi ön planda tutarak, taze ve seçkin malzemelerle hazırlanan menümüzle lezzet yolculuğuna çıkıyoruz. Betro Burger,o olarak, hedr bir müşterimizin beklentilerini aşmayı hedefleyen bir ekibiz. Müşteri odaklı yaklaşımımız ve sürekli gelişen iş modelimizle, fast-food endüstrisinde öncü bir konuma ulaşmayı hedefliyoruz. Her ısırıkta lezzet ve her deneyimde memnuniyet vaat eden Betro Burger, sizleri lezzet dolu bir yolculuğa davet ediyor. </p>
        </div>
      </div>
      {/* Third Container */}
      <Container className='purple' style={{ zIndex: 1110, position: "relative", width: "100%", height: "auto", paddingTop: "100px" }} fluid>
        <Row>
          <Col xs={3}>
            <p className='homepage-text-5'>BU</p>
            <p className='homepage-text-5'>DÜNYADAN</p>
            <p className='homepage-text-5'>DEĞİL</p>
          </Col>
          <Col xs={9} style={{ position: "relative", display: "flex", justifyContent: "flex-end", paddingRight: 0 }}>
            <div style={{ position: "relative", width: "100%" }}>
              <img src="https://betro.s3.eu-central-1.amazonaws.com/home_burger_astronot.png" style={{ width: "100%", zIndex: 1 }} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
