import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './JoinUs.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function JoinUs() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container fluid className='joinus-backgroundfor'>
      <img className='secpic' src="https://betro.s3.eu-central-1.amazonaws.com/secpic.png" alt="SecPic" />
      <img className='onepic' src="https://betro.s3.eu-central-1.amazonaws.com/onepic.png" alt="OnePic" />

      <img className='glasses' src="https://betro.s3.eu-central-1.amazonaws.com/glasses.png" alt="glasses" />

      <img className='starto' src="https://betro.s3.eu-central-1.amazonaws.com/star.png" />
      <img className='startree' src="https://betro.s3.eu-central-1.amazonaws.com/star.png" />
      <Row>
        <Col lg={6} className='backgroundone'>
          <p className='joinustitle'>
            BİZİ<br /> TANIMAK<br /> İSTER<br /> MİSİN?
          </p>
          <p>
            Betro Burger olarak, taze ve kaliteli malzemeler kullanarak müşteri memnuniyetini ön planda tutuyoruz. Misafirlerimizin her ziyaretinde tatmin olmalarını sağlamak için sürekli olarak yenilenen ve gelişen bir menü sunuyoruz. Ayrıca, çevre dostu ambalajlar kullanarak sürdürülebilir bir yaklaşım benimseyerek, hem lezzet hem de çevre bilinci konusunda öncü olmayı hedefliyoruz. Betro Burger olarak, her bir müşterimizin deneyimini unutulmaz kılmak için çalışıyoruz ve lezzet dolu bir yolculuğa davet ediyoruz.<br /><br />
            Betro Burger hakkında daha fazla bilgi almak mı istiyorsunuz? Lütfen aşağıdaki formu doldurun ve bizimle iletişime geçin. Size en kısa sürede geri dönüş yapacağız.
          </p>
          <div style={{ textAlign: 'center' }}>
            <Image className='contact-btn' src="https://betro.s3.eu-central-1.amazonaws.com/contactbtn.png" fluid onClick={handleShow} />
          </div>
        </Col>
        <Col lg={6} className='backgroundto'>
          <Image className="seller-img" src="https://betro.s3.eu-central-1.amazonaws.com/seller.png" fluid />
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header className='custom-modal-header'>
          <button type="button" class="btn-close btn-close-white" onClick={handleClose} aria-label="Close"></button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginLeft: "20px" }}>
            <Form>
              <Row>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label style={{ color: "white" }}>İsim - Soyisim:</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formBasicBudget">
                    <Form.Label style={{ color: "white" }}>Telefon Numarası:</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label style={{ color: "white" }}>E-mail:</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicBudget">
                    <Form.Label style={{ color: "white" }}>Şube:</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Beşiktaş"
                        name="group1"
                        type="radio"
                        id={`inline-radio-1`}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        inline
                        label="Kadıköy"
                        name="group1"
                        type="radio"
                        id={`inline-radio-2`}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        inline
                        label="Taksim"
                        name="group1"
                        type="radio"
                        id={`inline-radio-2`}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{ color: "white" }}>Ek Bilgiler:</Form.Label>
                    <Form.Control
                      className="extra-info-textarea"
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button type="submit" style={{ width: "180px", height: "40px", backgroundColor: "black", border: "1px solid #3DAE2B", borderRadius: "6px" }}>
                  Gönder
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default JoinUs;
