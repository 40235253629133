import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Career.css';

function Career() {
  return (
    <Container fluid className='backgroundfor'>
      <img className='bgnoise' src="https://betro.s3.eu-central-1.amazonaws.com/bgnoise.png" />
      <img className='starone' src="https://betro.s3.eu-central-1.amazonaws.com/star.png" />
      <img className='starto' src="https://betro.s3.eu-central-1.amazonaws.com/star.png" />
      <img className='startree' src="https://betro.s3.eu-central-1.amazonaws.com/star.png" />
      <Image className='Career-betro-pic' src="https://betro.s3.eu-central-1.amazonaws.com/betro.png" alt="Betro Burger" fluid />
      <Container style={{zIndex: '1000'}}>
        <Row className='align-items-center'>
          <Col md={8}>
            <div className='careerside-one'>
              <p className='Careertitle'>KARİYER</p>
              <p className='Careertxt'>Betro Burger, dinamik bir iş ortamında kariyer yapmak isteyen yetenekli ve tutkulu bireyler için çeşitli fırsatlar sunmaktadır. İnsan kaynaklarına ve çalışan memnuniyetine büyük önem veren bir firma olarak, çalışanlarımızın kişisel ve mesleki gelişimlerini desteklemek için çeşitli eğitim ve gelişim programları sunmaktayız. Betro Burger ailesine katılan herkes, işlerine duyduğumuz tutku ve sadakatle birlikte çalışma ortamında kendilerini geliştirme ve büyüme fırsatı bulur. Çalışanlarımıza eşitlik ve adalet ilkeleri doğrultusunda kariyerlerini ilerletme ve yeni yetenekler kazanma fırsatı sunuyoruz. Eğer siz de dinamik bir ortamda kariyer yapmak ve fast-food endüstrisinin lider bir markasının bir parçası olmak istiyorsanız, Betro Burger sizin için doğru adres!</p>
              <Form>
                <Row>
                  <Col xs={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label style={{ color: "white" }}>İsim Soyisim:</Form.Label>
                      <Form.Control type="text" placeholder='İsim Soyisim' />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-3" controlId="E-mail">
                      <Form.Label style={{ color: "white" }}>E-mail:</Form.Label>
                      <Form.Control type="text" placeholder='E-mail' />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col xs={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label style={{ color: "white" }}>Telefon Numarası:</Form.Label>
                      <Form.Control type="text" placeholder='Telefon Numarası' />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Şube</Form.Label>
                      <Form.Select>
                        <option>Beşiktaş</option>
                        <option>Kadıköy</option>
                        <option>Taksim</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div style={{ textAlign: 'right' }}>
                  <Button type="submit" style={{ width: "100px", height: "40px", backgroundColor: "#3DAE2B", border: "1px solid #3DAE2B", borderRadius: "5px" }}>
                    Gönder!
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          <Col md={4}>
            <div className='careerside-to'>
              <Image src="https://betro.s3.eu-central-1.amazonaws.com/career.png" fluid />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Career;
